export default {
  EDIT_LETTER: 'EDIT_LETTER',
  EDIT_QUESTION: 'EDIT_QUESTION',
  EDIT_ANSWER: 'EDIT_ANSWER',
  ANSWER_BUTTON_CLICK: 'ANSWER_BUTTON_CLICK',
  ANSWER_ONSUBMIT: 'ANSWER_ONSUBMIT',
  SET_ANSWER_ARRAY: 'SET_ANSWER_ARRAY',
  SET_TICK_QUESTION: 'SET_TICK_QUESTION',
  EDIT_EMAIL: 'EDIT_EMAIL',
  SET_LTR: 'SET_LTR',
  SET_SHOW: 'SET_SHOW'
};
